<!--
File: TrafficSites.vue
Description: show list of traffic sites entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <!--div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region" data-vv-name="selected_region"
            :initial_value="selected_region" />
        </div-->
        <div v-show="total > 0" class="md-layout-item md-small-size-100 md-size-20">
            <BaseDropdown :label="$t('label.select_road')" v-model="selected_road" :items="roadsInTrafficSitesList"
              :displayField="'name'" :valueField="'id'" />
          </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px"
              :placeholder="$t('label.search_records')" v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addTrafficSite"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selected_road" :md-label="$t('road_network.road')" md-sort-by="sdr_road">
            {{ item.sdr_road }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section')" md-sort-by="section">
            {{ item.section }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="traffic_site_desc">
            {{ item.traffic_site_desc }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.start_distance_m')" md-sort-by="start_distance">
            {{ item.start_distance | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.end_distance_m')" md-sort-by="end_distance">
            {{ item.end_distance | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.surveyed_distance')" md-sort-by="surveyed_distance">
            {{ item.surveyed_distance | numFormat(0) }}
          </md-table-cell>
          <!--md-table-cell style="width:10%" :md-label="$t('traffic.length_preset')" md-sort-by="length_preset">
            {{ item.length_preset }}
          </md-table-cell-->
          <md-table-cell :md-label="$t('traffic.aadt_coef_set')" md-sort-by="aadt_coef_set">
            {{ item.aadt_coef_set }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editTrafficSite(item.traffic_site_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { Pagination } from '@/components'
import Swal from 'sweetalert2'
import Fuse from 'fuse.js'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import { customSortMixin } from '@/mixins/customSortMixin'

export default {
  name: 'traffic-sites-all',
  mixins: [customSortMixin],
  data() {
    return {
      selected_region: 0,
      selected_road: 0,
      showSpinner: false,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 20, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['traffic_site_desc', 'sdr_road', 'section'],
      searchedData: [],
      currentSort: 'traffic_site_desc',
      currentSortOrder: 'asc',
      fuseSearch: null,
      footerTable: []
    }
  },
  components: {
    Pagination,
    RegionsDropdown,
    BaseDropdown,
  },

  mounted() {
    this.showSpinner = true
    this.$store.dispatch('LOAD_HISTORY').then((history) => {
      if (history.for === 'trafficsites' && history.use) {
        this.selected_region = history.data.selected_region
        this.selected_road = history.data.selected_road
        this.searchQuery = history.data.searchQuery
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        this.$nextTick(() => {
          this.pagination.currentPage = history.data.currentPage
        })
        this.$store.dispatch('CLEAR_HISTORY')
        this.showSpinner = false
      }
      this.reloadData()
    })
  },
  methods: {
    reloadData() {
      this.showSpinner = true
      this.$store.dispatch('LOAD_TRAFFIC_SITES').then(() => {
        this.showSpinner = false
      })
    },
    save_history() {
      const hist = {
        selected_region: this.selected_region,
        selected_road: this.selected_road,
        searchQuery: this.searchQuery,
        currentSort: this.currentSort,
        currentSortOrder: this.currentSortOrder,
        perPage: this.pagination.perPage,
        currentPage: this.pagination.currentPage
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'trafficsites' })
    },
    editTrafficSite(traffic_site_id) {
      this.save_history()
      this.$router.push(`/inventory_data/traffic/upd/${traffic_site_id}`)
    },
    addTrafficSite() {
      this.save_history()
      this.$router.push('/inventory_data/traffic/add')
    },
    handleDelete(item) {
      console.log(item)
      const alert = {
        title: this.$t('modal.are_you_sure'),
        html: this.$t('modal.you_want_delete', { name: item.traffic_site_desc }) + '<br><br>' +
          this.$t('modal.the_operation_cannot_be_canceled'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('modal.yes_delete'),
        cancelButtonText: this.$t('buttons.cancel'),
      }
      this.save_history()
      Swal.fire(alert).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_TRAFFIC_SITE', item.traffic_site_id).then(
            () => {
              this.reloadData()
              Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.traffic_site_desc }), 'success')
            },
            (err) => {
              Swal.fire(this.$t('messages.error'), err, 'error')
            }
          )
        }
      })
    },
  },

  computed: {
    ...mapState({
      trafficList: (state) => state.TrafficSites.list,
    }),
    ...mapGetters(['amIAdmin', 'roadsInTrafficSitesList']),
    queriedData() {
      const result = !this.searchQuery ? this.tableData : this.searchedData //? this.searchedData : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      /*let result = (!this.selected_region) ? this.trafficList : this.trafficList.filter((item) => {
        return item.fk_region === this.selected_region
      })*/
      let result = (!this.selected_road) ? this.trafficList : this.trafficList.filter((item) => {
        return item.fk_road == this.selected_road
      })

      // Fuse search initialization.
      this.fuseSearch = new Fuse(result, { keys: this.propsToSearch, threshold: 0.3 })

      return result
    }
  },
  watch: {
    searchQuery(value) {
      if (this.trafficList.length == 0) {
        this.searchedData = []
        return
      }
      this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.tableData
    }
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>